import React from 'react'
import { Link } from '../../plugins/gatsby-plugin-atollon'
import FormComponent from "./form"
import FooterVisual from "./structured-text/blocks/footer-visual"
import { Linkedin } from "./icons"
import IMG1 from '../assets/images/img-01.png'
import IMG2 from '../assets/images/img-02.png'

const Footer = ({subscribeForm, pathname}) => {

  let footerClass = 'footer'

  return (
    <footer className={footerClass}>
      <div className='footer__inner'>
        <div className='footer__top'>
          <div className='footer__nav'>
            <ul>
              <li><Link className='btn btn--orange' to='/contact/'>New South Wales</Link></li>
              <li><Link className='btn btn--orange' to='/contact/'>Queensland</Link></li>
              <li><Link className='btn btn--orange' to='/contact/'>South Australia</Link></li>
              <li><Link className='btn btn--orange' to='/contact/'>Tasmania</Link></li>
              <li><Link className='btn btn--orange' to='/contact/'>Victoria</Link></li>
            </ul>
          </div>

          <div className='footer__form'>
            <div className='footer__certified'>
              <img loading='lazy' src={IMG1} alt="FSC" />
              <img loading='lazy' src={IMG2} alt="FSC" />
            </div>
          </div>
        </div>

        <FooterVisual pathname={pathname} />

        <div className="footer__bottom">
          <ul>
            <li><Link to='https://www.linkedin.com/company/fsc-group/' target='_blank' rel='noopener noreferrer'><Linkedin/></Link></li>
            <li>© {new Date().getFullYear()} FSC Group</li>
            <li><Link to='/privacy-policy/'>Privacy Policy</Link></li>
            <li><Link to='/modern-slavery-statement/'>Modern Slavery Statement</Link></li>
          </ul>
          <Link target='__blank' to='https://atollon.com.au/?utm_source=FSC&utm_medium=website&utm_campaign=referrer&utm_term=services'>Design by Atollon</Link>
        </div>
      </div>
    </footer>
  )
}

export default Footer
